/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker, Form, Input, Radio, Select, Switch, Checkbox, TimePicker, Cascader, InputNumber, Slider, AutoComplete,
} from 'antd';
import moment from 'moment';
import { range } from 'lodash';

export const inputType = {
  input: 'INPUT',
  input_password: 'PASSWORD',
  inputNumber: 'INPUT_NUMBER',
  select: 'SELECT',
  switch: 'SWITCH',
  datePicker: 'DATE_PICKER',
  timePicker: 'TIME_PICKER',
  rangePicker: 'RANGE_PICKER',
  timeRangePicker: 'TIME_RANGE_PICKER',
  radio: 'RADIO',
  radioGroup: 'RADIO_GROUP',
  checkbox: 'CHECKBOX',
  checkboxGroup: 'CHECKBOX_GROUP',
  cascader: 'CASCADER',
  inputTextArea: 'INPUT_TEXTAREA',
  slider: 'SLIDER',
  autoComplete: 'AUTOCOMPLETE',
};

const FormItem = ({
  mult,
  form,
  type,
  inputProps,
  showAllOption,
  ...rest
}) => {
  const [multHelper, setMultHelper] = useState({});
  const generateInput = (multCount) => {
    let input = <Input {...inputProps} />;
    switch (type) {
      case inputType.inputNumber: {
        if (!mult) {
          input = <InputNumber {...inputProps} />;
          break;
        }
        const helper = () => ({
          min1: inputProps.min,
          min2: form.getFieldValue(rest.name.name1) || inputProps.min,
          max1: form.getFieldValue(rest.name.name2) || inputProps.max,
          max2: inputProps.max,
        });
        input = (
          <InputNumber
            {...inputProps}
            min={multCount === 1 ? multHelper.min1 : multHelper.min2}
            max={multCount === 1 ? multHelper.max1 : multHelper.max2}
            onChange={() => {
              setMultHelper({ ...helper() });
            }}
          />
        );
        break;
      }
      case inputType.input_password:
        input = <Input.Password {...inputProps} />;
        break;
      case inputType.select:
        input = <Select {...inputProps} options={showAllOption && inputProps.mode !== 'multiple' ? [{ label: '全部', value: '' }].concat(inputProps.options) : inputProps.options} />;
        break;
      case inputType.autoComplete:
        input = <AutoComplete {...inputProps} />;
        break;
      case inputType.switch:
        input = <Switch {...inputProps} />;
        break;
      case inputType.datePicker:
        if (!mult) {
          input = (<DatePicker {...inputProps} />);
          break;
        }
        input = (
          <DatePicker
            width={250}
            {...inputProps}
            defaultValue={multCount === 1 ? inputProps.defaultValue1 : inputProps.defaultValue2}
            disabledDate={(current) => {
              if (multCount === 1) {
                const moment2 = form.getFieldValue(rest.name.name2);
                return moment2 && current > moment(moment2).endOf('day');
              }
              const moment1 = form.getFieldValue(rest.name.name1);
              return moment1 && current < moment(moment1).startOf('day');
            }}
          />
        );
        break;
      case inputType.timePicker: {
        // 非范围模式
        if (!mult) {
          input = <TimePicker {...inputProps} />;
          break;
        }
        // 范围的起
        if (multCount === 1) {
          input = (
            <TimePicker
              {...inputProps}
              disabledHours={() => {
                const oMoment = form.getFieldValue(rest.name.name2);
                return oMoment ? range(0, 24).splice(Number(oMoment.format('HH')) + 1, 24) : [];
              }}
              disabledMinutes={(selectHour) => {
                const oMoment = form.getFieldValue(rest.name.name2);
                return (oMoment && selectHour === Number(oMoment.format('HH'))) ? range(0, 60).splice(Number(oMoment.format('mm')) + 1, 60) : [];
              }}
              disabledSeconds={(selectHour, selectMin) => {
                const oMoment = form.getFieldValue(rest.name.name2);
                return (oMoment && selectHour === Number(oMoment.format('HH')) && selectMin === Number(oMoment.format('mm')))
                  ? range(0, 60).splice(Number(oMoment.format('ss')) + 1, 60) : [];
              }}
            />
          );
          break;
        }
        // 范围的止
        input = (
          <TimePicker
            {...inputProps}
            disabledHours={() => {
              const oMoment = form.getFieldValue(rest.name.name1);
              return oMoment ? range(0, 24).splice(0, Number(oMoment.format('HH'))) : [];
            }}
            disabledMinutes={(selectHour) => {
              const oMoment = form.getFieldValue(rest.name.name1);
              return (oMoment && selectHour === Number(oMoment.format('HH'))) ? range(0, 60).splice(0, Number(oMoment.format('mm'))) : [];
            }}
            disabledSeconds={(selectHour, selectMin) => {
              const oMoment = form.getFieldValue(rest.name.name1);
              return (oMoment && selectHour === Number(oMoment.format('HH')) && selectMin === Number(oMoment.format('mm')))
                ? range(0, 60).splice(0, Number(oMoment.format('ss'))) : [];
            }}
          />
        );
        break;
      }
      case inputType.timeRangePicker:
        input = <TimePicker.RangePicker {...inputProps} />;
        break;
      case inputType.rangePicker:
        input = <DatePicker.RangePicker {...inputProps} />;
        break;
      case inputType.radio:
        input = <Radio {...inputProps} />;
        break;
      case inputType.radioGroup:
        input = <Radio.Group {...inputProps} />;
        break;
      case inputType.checkbox:
        input = <Checkbox {...inputProps} />;
        break;
      case inputType.checkboxGroup:
        input = <Checkbox.Group {...inputProps} />;
        break;
      case inputType.inputTextArea:
        input = <Input.TextArea {...inputProps} />;
        break;
      case inputType.cascader:
        input = <Cascader {...inputProps} />;
        break;
      case inputType.slider:
        input = <Slider {...inputProps} />;
        break;
      default:
        break;
    }
    return input;
  };

  if (!mult) {
    return (
      <Form.Item {...rest}>
        {generateInput(1)}
      </Form.Item>
    );
  }
  return (
    <div style={{ display: 'flex' }}>
      <Form.Item {...rest} name={rest.name.name1}>
        {generateInput(1)}
      </Form.Item>
      <span style={{ paddingLeft: 10, paddingRight: 10, marginTop: 5 }}>至</span>
      <Form.Item {...rest} name={rest.name.name2} label="">
        {generateInput(2)}
      </Form.Item>
    </div>
  );
};

FormItem.propTypes = {
  mult: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(inputType).map((key) => inputType[key])),
  inputProps: PropTypes.shape({
    placeholder: PropTypes.string,
    mode: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  showAllOption: PropTypes.bool,
};

FormItem.defaultProps = {
  mult: false,
  type: inputType.input,
  inputProps: {},
  showAllOption: true,
};

export default FormItem;
