import React from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';

export default {
  path: 'ycFinance',
  element: <Outlet />,
  options: {
    title: '云彩发薪',
    permissionKey: 'menu-ycFinance',
    key: 'menu-ycFinance',
    icon: <Icon.ContactsOutlined />,
    link: 'https://finance.ycrzky.com/sso',
  },
};
